<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ lang.label }} Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="title"
                rules=""
                name="The Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Title"
                          name="title"
                          fou
                          v-model="formData.title">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            title: "",
          }
        }
      },
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
    }
  }
</script>
<style>
</style>
